import Dynamic from 'next/dynamic';

import FluencyJourneySkeleton from 'components/Shared/FluencyJourney/Skeleton';

export const Hero = Dynamic(
  () => import('components/CMSComponents/Heroes/HeroComponent'),
  { ssr: true }
);

export const FluencyWeekHero = Dynamic(
  () => import('components/CMSComponents/Heroes/FluencyWeek'),
  { ssr: true }
);

export const BirthdayHero = Dynamic(
  () => import('components/CMSComponents/BirthdayCampaign'),
  { ssr: true }
);

export const TechHero = Dynamic(
  () => import('components/CMSComponents/Heroes/TechHero'),
  { ssr: true }
);

export const PartnerHero = Dynamic(
  () => import('components/CMSComponents/Heroes/PartnerHero'),
  { ssr: true }
);

export const HeroWithBullets = Dynamic(
  () => import('components/CMSComponents/Heroes/Hero2Component'),
  { ssr: true }
);

export const TechJourney = Dynamic(
  () => import('components/TechVertical/TechJourney'),
  { ssr: true }
);

export const WhoIs = Dynamic(() => import('components/TechVertical/WhoIs'), {
  ssr: true,
});

export const Warranty = Dynamic(
  () => import('components/TechVertical/Warranty'),
  {
    ssr: true,
  }
);

export const Overview = Dynamic(
  () => import('components/TechVertical/Overview'),
  {
    ssr: true,
  }
);

export const LearnTrack = Dynamic(
  () => import('components/TechVertical/LearnTrack'),
  {
    ssr: true,
  }
);

export const Doubts = Dynamic(() => import('components/TechVertical/Doubts'), {
  ssr: true,
});

export const Method = Dynamic(
  () => import('components/SubscriptionComponents/MethodComponent'),
  {
    ssr: true,
  }
);

export const ComparativeTable = Dynamic(
  () => import('components/CMSComponents/ComparativeTableComponent'),
  {
    ssr: true,
  }
);

export const ExperienceCalibrator = Dynamic(
  () =>
    import('components/SubscriptionComponents/ExperienceCalibratorComponent'),
  {
    ssr: true,
  }
);

export const MediaPress = Dynamic(
  () => import('components/MediaPressComponent'),
  {
    ssr: true,
  }
);

export const Plans = Dynamic(
  () => import('components/CMSComponents/PlansComponent'),
  {
    ssr: true,
  }
);

export const Faq = Dynamic(() => import('components/FaqComponent'), {
  ssr: true,
});

export const DynamicFaq = Dynamic(
  () => import('components/DynamicFaqComponent'),
  {
    ssr: true,
  }
);

export const BannerOffer = Dynamic(
  () => import('components/SubscriptionComponents/BannerOfferComponent'),
  {
    ssr: true,
  }
);

export const FreeClassesInfo = Dynamic(
  () => import('components/SubscriptionComponents/FreeClassesInfo'),
  {
    ssr: true,
  }
);

export const PlansTestimonies = Dynamic(
  () => import('components/CMSComponents/Testimonies'),
  { ssr: true }
);

export const ScarcityTimer = Dynamic(
  () => import('components/MultiCheckoutComponents/ScarcityTimerComponent'),
  { ssr: true }
);

export const FluencyJourney = Dynamic(
  () => import('components/Shared/FluencyJourney'),
  { ssr: true, loading: () => <FluencyJourneySkeleton /> }
);

export const BannerComponent = Dynamic(
  () => import('components/CMSComponents/BannerComponent'),
  { ssr: true }
);
